import React from "react";
import Layout from "../components/Layout/Layout";

import Banner from "../images/misc/zanskar.jpeg";
import TOI from "../images/misc/TOI 2.jpeg";
import Team from "../images/misc/team_sikkim.jpg";
import { navigate } from "gatsby";

export default function AboutUs() {
  return (
    <Layout>
      <div className="absolute top-0 left-0 h-[500px] blur-[2px]">
        <img
          src={Banner}
          className="object-cover w-full h-full md:h-auto md:w-auto"
        />
      </div>
      <div className="container relative p-8 mx-auto text-center md:p-36 ">
        <h6 className="text-2xl font-light text-white uppercase before:content-['♦'] before:text-kr-yellow after:content-['♦'] after:text-kr-yellow before:pr-4 after:pl-4">
          ABOUT US
        </h6>
        <h1 className="my-4 font-semibold text-white uppercase text-7xl">
          CLUB INFO
        </h1>
      </div>

      <section className="relative py-12 bg-neutral-800 md:py-36">
        <div className="container relative flex-wrap items-center px-4 mx-auto md:flex md:px-0">
          <div className="flex-1 md:p-16">
            <h2 className="font-semibold text-white uppercase text-7xl">
              We are <span className="text-kr-yellow">Kolkata Riders</span>
            </h2>
            <p className="py-12 font-sans text-xl text-neutral-400">
              Kolkata Riders (registered with govt. of WB. India Govt.
              Registration no – S/1L/88779) is a motorcycle touring club for
              motorcycle enthusiasts and Bikers who travel almost all corners of
              our great nation as well as aboard. It is a platform for bikers to
              share their experience with other like-minded bikers around
              Kolkata and Bengal. Our members are scattered around the city and
              its neighbourhood and are friendly, energetic, experienced and
              always ready to help new people who wish to travel along with
              their own motorcycles. Over the past few years, our members have
              visited many places like, Ladakh, Himachal Pradesh, Rajasthan
              (Longewala Border India-Pakistan), Nainital, Sikkim, Orissa,
              different parts of Bengal, Nepal and Bhutan, on their motorcycles.
              We have conducted various rides for different cause, that include
              the’ Respect Women Ride ‘ in protest of the shameful rape incident
              in Delhi. We are growing very fast and our club website is going
              to be launched very soon where riders will be able to share their
              experience with the whole world. It is a club, by the Bikers, for
              the Bikers and of the Bikers. Join us, if you want to share your
              experience and become our friend. We have conducted rides for
              different cause for social upliftment and well-being, that include
              the’ Respect Women Ride’. We have also conducted some social
              welfare activities for the poor & backward children in Sundarban,
              Bhutan etc. a Road Safety awareness rides for letting other
              2-wheeler riders know the value of their life and to aid in the
              act of wearing Helmets and proper gears while riding. Also helping
              the poor is also one of the social acts on our calendar.
            </p>
            <button
              className="px-8 py-4 text-2xl text-white uppercase explore-us w-80 bg-kr-yellow hover:bg-kr-dark-yellow font-regular"
              onClick={() => navigate("/ride-report")}
            >
              Explore Our Rides
            </button>
            <div className="w-full py-12">
              <img src={Team} />
            </div>
          </div>
          <div className="flex flex-col items-end flex-1">
            <div className="max-w-[500px]">
              <img src={TOI} />
            </div>
            <h2 className="py-24 font-semibold text-white border-b-4 text-7xl border-b-kr-yellow">
              WE KEPT WHAT WE LOVED ABOUT THE BIKE
            </h2>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export const Head = () => <title>About Us</title>;
